@import "@/styles/base/_colors";

.productListingSearchWrapper {
  :global {
    .product-listing,
    .search-result {
      //sort
      .sort {
        position: relative;
        margin-left: 30px;
        &__toggle {
          display: flex;
          align-items: center;
          position: relative;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.45px;
          color: $color-slate;
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          outline: none;

          &-icon {
            width: 15.73px;
            height: 9.07px;
            margin: 7px 0 7.93px 10.15px;
            transform: rotateX(180deg);
            transition: transform 0.5s ease-in-out;
          }
        }

        &__drop-down {
          max-height: 0px;
          position: absolute;
          top: 46px;
          right: 0;
          background-color: $color-white;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.15);
          list-style: none;
          padding: 0;
          overflow: hidden;
          transition: max-height 0.1s ease-in-out;
          width: max-content;
        }

        &__item {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 1.125rem;
          line-height: 1.5rem;
          letter-spacing: -0.45px;
          text-align: left;
          color: $color-dark-gray;

          cursor: pointer;
          opacity: 0;
          padding: 5px 25px;
          transition: opacity 0.1s ease-in-out;
          margin: auto 2px;

          &:first-child {
            padding-top: 5px;
          }
          &:last-child {
            padding-bottom: 10px;
          }
          &:hover {
            background-color: $color-off-white;
          }
          &.active {
            background-color: $color-off-white;
            color: $color-dark-gray;
          }
        }
        &--open {
          .sort__drop-down {
            max-height: 280px;
            z-index: 10;
            padding-top: 15px;
            padding-bottom: 15px;
          }

          .sort__item {
            opacity: 1;
            margin: 1px; // to make keyboard focus visible
            text-align: right;
          }
        }
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section {
          right: 15px;
          .sort__drop-down {
            right: 0;
          }
        }
      }
      @media screen and (max-width: 768px) {
        &__controls {
          section {
            right: 15px;
            .sort__drop-down {
              right: 0;
            }
          }
        }
      }
    }
  }
}
