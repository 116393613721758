@import "@/styles/base/_colors";

.productListingFilterWrapper {
  :global {
    .product-listing,
    .search-result {
      .filter {
        height: calc(100vh - 64px);
        overflow-y: auto;
        -ms-overflow-style: none;
        padding: 0 21px 50px 2px; //required for ADA focus border to be visible
        &::-webkit-scrollbar {
          display: none;
        }
        &.os-theme-dark {
          .os-scrollbar {
            padding: 0px;
            &-vertical {
              width: 7px;
            }
            &-track {
              background-color: transparent;
            }
            &-handle {
              border-radius: 4px;
              background-color: $color-medium-gray;
            }
          }
          .os-content {
            padding-left: 0px !important;
            @media screen and (max-width: 768px) {
              height: auto !important;
            }
          }
        }
        .radio-wrapper {
          margin-bottom: 0;
          label {
            span {
              color: $color-dark-gray;
            }
          }
          input[type="radio"]:checked + span + span {
            color: $color-charcoal;
          }
        }

        &--show-less {
          .collapsible-outer {
            display: none;

            &:nth-child(-n + 5) {
              display: block;
            }
          }
        }

        &__mobile-header {
          display: none;
        }

        &__apply {
          display: none;
          width: 100%;
          height: 60px;
          background-color: $color-charcoal;
          font-family: "Helvetica Now Text W05 Medium";
          font-size: 18px;
          line-height: 1.33;
          letter-spacing: -0.35px;
          color: $color-white;
          border: none;
          outline: auto;
          margin-top: 40px;
          margin-bottom: 40px;
          transition: all 0.5s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;

          &-loader {
            border: 2px solid $color-kohler-blue-loader;
            border-left: 2px solid $color-white;
            animation: load 1s infinite linear;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }

        @keyframes load {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        &__show-more {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          padding: 0;
          margin: 0;
          margin-top: 14px;
          background: none;
          border: none;
          outline: none;
        }

        &__hide-discontinued {
          margin-top: 25px;
        }

        &__print-and-share {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          display: flex;
          padding-top: 40px;
          padding-bottom: 40px;

          span {
            margin-right: 30px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              height: 16px;
              margin-right: 10px;
            }
          }
        }

        &__need-help-container {
          margin-top: 10px;
          @media only screen and (max-width: 991px) {
            margin-top: 50px;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            padding-bottom: 40px;
          }

          .need-help-label {
            color: #212121;
            font-family: "Helvetica Now Text W05 Regular";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.35px;
            line-height: 24px;
          }

          .need-help-links-container {
            margin: 10px 0;
            color: #757575;

            .need-help-link {
              color: #757575;
              font-family: "Helvetica Now Text W05 Regular";
              font-style: normal;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.35px;
              line-height: 24px;
              text-decoration: underline;
            }
          }
        }

        &__chips {
          transition: all 0.5s ease;
          &-tag {
            display: inline-flex;
            border-radius: 5px;
            border: solid 1px $color-medium-gray;
            font-family: "Helvetica Now Text W05 Regular";
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-dark-gray;
            padding: 8px 15px;
            align-items: center;
            margin-bottom: 10px;
            margin-right: 10px;
          }

          &-close-icon {
            width: 10px;
            height: 10px;
            margin-left: 15px;
            cursor: pointer;
          }

          &-clear-all {
            font-family: "Helvetica Now Text W05 Medium";
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            text-decoration: underline;
            margin-top: 5px;
            margin-bottom: 20px;
            cursor: pointer;
            @media screen and (max-width: 1023px) {
              margin-bottom: 20px;
              margin-top: 5px;
            }
          }
        }

        .social-share__box {
          position: relative;
        }

        .collapsible-outer {
          &:first-child {
            .Collapsible {
              border-top: 1px solid $color-dark-gray;
            }
          }
        }

        .Collapsible {
          border-bottom: 1px solid $color-dark-gray;
          &.is-open {
            .Collapsible__contentInner {
              opacity: 1;
              .filter-list {
                margin: 0;
                li {
                  list-style: none;
                  .checkbox {
                    margin-left: 2px;
                    margin-top: 5px;
                    padding: 0 2px;
                  }
                }
              }
            }
          }

          &__trigger {
            width: 100%;
            display: block;
            font-family: "Helvetica Now Text W05 Medium";
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-charcoal;
            padding: 13px 0px;
            cursor: pointer;

            div {
              display: flex;
              justify-content: space-between;
              .plus {
                width: 14px;
                height: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px;
                .line {
                  background: $color-charcoal;
                  transition: all 0.5s ease;
                  &.line-1 {
                    width: 100%;
                    height: 1px;
                  }
                  &.line-2 {
                    width: 1px;
                    height: 100%;
                    transform: translateX(-7px);
                  }
                }
              }
            }

            &.is-open {
              .plus {
                .line {
                  &.line-2 {
                    height: 0;
                  }
                }
              }
            }
          }

          &__contentInner {
            opacity: 0;
            transition: all 0.3s ease-in-out;
            padding-bottom: 5px;
          }
        }
        @media screen and (max-width: 768px) {
          &__inner-container {
            padding-bottom: 50px;
          }

          &__print-and-share {
            display: none;
          }

          &__title-info {
            margin-top: 0;
            height: 145px;
          }

          &__apply {
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }

          &__mobile-sort {
            margin-bottom: 20px;
            .radio {
              margin-bottom: 15px;
              &-wrapper {
                display: grid;
                .radio__custom {
                  width: 20px;
                  height: 20px;
                  &::after {
                    background: $color-charcoal;
                  }
                }
              }
            }
          }

          &__mobile-sort-title {
            color: $color-slate;
            font-family: "Helvetica Now Text W05 Medium";
            font-size: 16px;
            letter-spacing: -0.35px;
            line-height: 24px;
          }
        }
        @media only screen and (max-width: 991px) {
          height: 100vh;
          padding-right: 32px;
          @media (max-width: 480px) {
            padding-right: 18px;
          }

          &__mobile-header {
            display: flex;
            height: 60px;
            padding: 10px 0 20px;
            justify-content: space-between;
            margin: auto;
          }

          &__filter-list {
            span.Collapsible__trigger:focus-visible {
              outline: none !important;
            }
          }

          &__footer {
            background: $color-white;
            position: fixed;
            bottom: 0;
            width: calc(100vw - 64px);
            @media (max-width: 480px) {
              width: calc(100vw - 36px);
            }
            button {
              margin: 18px 0;
              width: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: $color-charcoal;
              @media (max-width: 480px) {
                margin: 18px 0;
              }
            }
          }

          &__mobile-sort {
            margin-bottom: 5px;
            .radio {
              margin-bottom: 15px;
              &:focus-visible {
                outline: none !important;
              }
              &-wrapper {
                display: grid;
                margin-bottom: 0px;
                .radio__custom {
                  width: 20px;
                  height: 20px;
                  &::after {
                    background: #212121;
                  }
                }
              }
            }
            &-title {
              color: $color-slate;
              font-family: "Helvetica Now Text W05 Medium";
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.35px;
              line-height: 24px;
              padding-bottom: 15px;
            }
          }
        }
        @media only screen and (max-width: 812px) and (orientation: landscape) {
          &__mobile-sort {
            margin-bottom: 20px;
            .radio {
              &:last-child {
                margin-bottom: 0px;
              }
              margin-bottom: 15px;
            }
          }
        }
        @media only screen and (max-width: 860px) and (orientation: landscape),
          only screen and (min-width: 768px) and (max-width: 991px) {
          &__inner-container {
            padding-bottom: 40px;
          }
          &__print-and-share {
            display: none;
          }
        }
      }
      @media screen and (max-width: 768px) {
        &__filter-toggle {
          display: flex;
          align-items: center;
          font-family: "Helvetica Now Text W05 Regular";
          padding: 0;
          margin: 0;

          span {
            font-family: "Helvetica Now Text W05 Regular";
            font-size: 18px;
            color: $color-charcoal;
            line-height: 24px;
            letter-spacing: -0.45px;
          }
        }

        &__close-icon {
          width: 16.26px;
          height: 16.26px;
        }
      }
    }
  }
}
